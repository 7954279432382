<template>
  <!-- table -->
  <div class="dashboard_rtc_panel_event" style="height: 100%">
    <div
      style="
        width: 100%;
        height: 16%;
        background-color: rgba(44, 44, 44, 1);
        border-radius: 10px;
      "
    >
      <div
        style="
          font-size: 1rem;
          height: 100%;
          color: white;
          display: flex;
          align-items: center;
          padding: 0 1rem;
        "
      >
        <img
          src="resources/img/icons/dashboard/event/EventList.svg"
          style="width: 1.5vw; float: left; margin-right: 0.5rem; height: 20px"
        />
        <span style="flex: 1; font-weight: 600">{{ $t("label.L0035") }}</span>
        <!-- <router-link :to="{path : '/event'}"> -->
        <!-- <img
          @click="routeEvent()"
          src="resources/img/icons/dashboard/menu.svg"
          style="width: 1vw; float: right; cursor: pointer"
        /> -->
        <!-- src="resources/img/icons/dashboard/more_horiz-24px.svg" -->
        <!-- </router-link> -->
      </div>
    </div>

    <b-container fluid class="scroll-test cctv_container" style="height: 84%">
      <thead style="color: #999999; display: flex">
        <th style="flex-basis: 38%; text-align: center">
          {{ $t("label.L0036") }}
        </th>
        <th style="flex-basis: 17%; text-align: center">
          {{ $t("label.L0087") }}
        </th>
        <th style="flex-basis: 12%; text-align: center" v-if="$i18n.locale == 'ko' ">
          {{ $t("label.L0037") }}
        </th>
        <th style="flex-basis: 1%; text-align: center" v-else>
          {{ $t("label.L0037") }}
        </th>
        <th style="flex-basis: 16%; text-align: center">
          {{ $t("label.L0038") }}
        </th>
        <th style="flex-basis: 9%; text-align: center" v-if="$i18n.locale == 'ko' ">
          {{ $t("label.L0039") }}
        </th>
        <th style="flex-basis: 1%; text-align: center" v-else>
          {{ $t("label.L0039") }}
        </th>
      </thead>
      <div
        style="
          display: flex;
          height: calc(100% - 41px);
          width: calc(100%-10px);
          overflow-y: scroll;
        "
      >
        <!-- {{ $t("label.L0035") }} 표시 -->
        <div class="virtual-scroll-box">
          <div class="virtualList">
            <div
              v-for="(event, index) in eventList"
              :key="index"
              style="
                display: flex;
                cursor: pointer;
                align-items: center;
                width: 100%;
              "
            >
              <!-- {{ $t("label.L0036") }}  -->
              <div
                style="flex-basis: 38%; text-align: left"
                class="divTableCell"
                @click="clickInfo(event)"
              >
                <img
                  style="margin-left: 15px"
                  v-bind:src="eventIcon(event.eventIcon)"
                /><span>{{ event.eventType }}</span>
              </div>

              <!-- 방면 -->
              <div
                style="
                  flex-basis: 19%;
                  text-align: center;
                  padding-left: 0px !important;
                "
                class="divTableCell"
                @click="clickInfo(event)"
              >
                {{ event.eventDirect }}
              </div>

              <!-- 발생위치 -->
              <div
                style="padding-left: 3px; flex-basis: 10%; text-align: right"
                class="divTableCell"
                @click="clickInfo(event)"
              >
                {{ event.position.toFixed(2) * 1000 + " m" }}
              </div>

              <!-- 발생시간 -->
              <div
                style="flex-basis: 19%; text-align: center"
                class="divTableCell"
                @click="clickInfo(event)"
              >
                {{ event.eventRealTime }}
              </div>

              <!-- 출처  -->
              <div
                style="flex-basis: 1%; text-align: center"
                class="divTableCell"
                @click="clickInfo(event)"
              >
                <img
                  v-bind:src="eventEquip(event.eventEquip)"
                  style="width: 1.8vw"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-container>
    <div>
      <!-- <EventModal ref="eventPop" :i_modal_data_obj="data_obj" :i_layout_type="'eventList'"  
      @update-obj="updateEmit"
      ></EventModal> -->
      <EventModal
        ref="eventPop"
        :propTitle="$t('label.L0002')"
        :darkCheck="true"
        @update-obj="updateEmit"
      ></EventModal>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import EventBus from "@/event-bus";
import EventModal from "../../event/components/eventPopup.vue";
import {
  getUpCount,
  getDownCount,
  getToggle,
  getOpenPopup,
} from "@/components/utils/dataConnet.js";
import qs from "qs";
import { filter, map, switchMap } from "rxjs/operators";
import {
  from,
  interval,
  Subscription,
  Subject,
  combineLatest,
  BehaviorSubject,
} from "rxjs";
import dayjs from "dayjs";
import VirtualList from "vue-virtual-scroll-list";
import { BContainer, BRow } from "bootstrap-vue";
export default {
  components: {
    "virtual-list": VirtualList,
    BContainer,
    BRow,
    EventModal,
  },
  name: "EventTable",
  data: function () {
    return {
      eventIcon: function (eventIcon) {
        var iconList = {
          "01": "resources/img/icons/dashboard/event/stopping-car.svg",
          "02": "resources/img/icons/dashboard/event/pedestrian.svg",
          "03": "resources/img/icons/dashboard/event/reverse.svg",
          "04": "resources/img/icons/dashboard/event/suddenStop.svg",
          "06": "resources/img/icons/dashboard/event/fire.svg",
          "09": "resources/img/icons/dashboard/event/bottleneck.svg",
          11: "resources/img/icons/dashboard/event/sound.svg",
          12: "resources/img/icons/dashboard/event/sound.svg",
          13: "resources/img/icons/dashboard/event/sound.svg",
          14: "resources/img/icons/dashboard/event/sound.svg",
        };

        return iconList[eventIcon];
      },
      selected_event_type_string: {
        "01": this.$t("label.L0027"),
        "02": this.$t("label.L0022"),
        "03": this.$t("label.L0023"),
        "04": this.$t("label.L0025"),
        "06": this.$t("label.L0024"),
        "09": this.$t("label.L0026"),
        11: this.$t("label.L0091"),
        12: this.$t("label.L0092"),
        13: this.$t("label.L0093"),
        14: this.$t("label.L0094"),
      },
      eventEquip: function (eventEquip) {
        var equipList = [
          "resources/img/icons/dashboard/sound_sticker.svg",
          "resources/img/icons/dashboard/radar_sticker.svg",
          "resources/img/icons/dashboard/cctv_sticker.svg",
        ];
        return equipList[eventEquip];
      },

      eventList: [],
      selectedEvent: "",
      selectedLinkCode: "",
      tunnel_to_linkcode: {
        ALL: ["ALL", "ALL"],
        WYT: ["LNK-0450-MCT", "LNK-0450-CMT"], //
      },
      selectTunnelCode: null,
      tunnel_nm: {
        TDT: this.$t("label.L0104"),
      },
      deviceInfo: [],
      dates: {
        start: new Date(),
        end: new Date(),
      },
      selected_direction_type: "ALL",
      selected_direction_type_string: {
        ALL: this.$t("label.L0087"),
        하행: this.$t("label.L0020"),
        상행: this.$t("label.L0019"),
      },
      selected_event_type: 999,
      /*selected_event_type_string : {
				1 : '{{ $t("label.L0027") }}',
				2 : '{{ $t("label.L0022") }}',
				3 : '{{ $t("label.L0023") }}',
				9 : '정체',
				999 : '돌발 유형'
			}, */
      selected_confirm_type: 999,
      selected_confirm_type_string: {
        0: this.$t("label.L0101"),
        1: this.$t("label.L0102"),
        2: this.$t("label.L0042"),
        3: this.$t("label.L0103"),
        999: this.$t("label.L0050"),
      },
      selected_search_type: "all",
      selected_search_type_string: {
        all: this.$t("label.L0021"),
        camera_id: this.$t("label.L0054"),
        position: this.$t("label.L0052"),
      },
      search_string: "",

      totalCount: 0,
      startIndex: 0,
      paging: {
        pageSize: 999,
        rangeSize: 5,
        rangeCnt: 1,
        endPage: 1,
        startPage: 1,
        curPage: 1,
        curRange: 1,
        pageCnt: 1,
        totalList: 1,
      },
      data_obj: {},
      tunnel_title: {
        "LNK-0450-MCT": " - " + this.$t("label.L0097"),
        "LNK-0450-CMT": " - " + this.$t("label.L0098"),
      },

      loading: false,
      flatPickerConfig: {
        allowInput: true,
        enableTime: true,
        defaultDate: new Date(),
      },
      originEventList: [],
      toggle: false,
      upVehcleCount: 0,
      downVehcleCount: 0,
      subs: new Subscription(),
      updateDate$: new BehaviorSubject(true),
    };
  },
  methods: {
    async updateEmit(item) {
      try {
        let data = {};
        data.rEventType = item.rEventType;
        data.confirmType = item.confirmType;
        data.memo = item.memo;
        const response = await this.api.updateEvent(item.no, data);
        // return response;
      } catch (err) {
        console.log("EventTable msg : ", err);
      }
      this.selectEventDashboardList();
    },
    clickInfo: function (event) {
      // 일단... 주석...
      this.$refs.eventPop.updatepopup(event);
    },
    goEvent: function () {},
    changeTunnel: function (data) {},
    selectEventList: function (i_curPage) {
      let me = this;

      let curPage = i_curPage;
      let link_code = me.tunnel_to_linkcode[me.selectedLinkCode];

      const start_date = dayjs(me.dates.start).format("YYYY-MM-DD HH:mm");
      const end_date = dayjs(me.dates.end).format("YYYY-MM-DD HH:mm");
      if (curPage > me.paging.pageCnt) {
        curPage = me.paging.pageCnt;
      }
      if (curPage < 1) {
        curPage = 1;
      }
      me.paging.curPage = curPage;
      me.loading = true;

      let params = {
        // linkCodeS : 'ALL',
        // linkCodeE : link_code[1],
        // directionType : me.selected_direction_type,
        linkCode: "ALL",
        techType: "ALL", // 검지기 종류
        eventType: me.selected_event_type,
        confirmType: me.selected_confirm_type,
        searchType: me.selected_search_type,
        searchString: me.search_string || " ",
        startDate: start_date,
        endDate: end_date,
        curPage: me.paging.curPage,
        pageSize: me.paging.pageSize,
      };
      me.subs.add(
        me.updateDate$
          .asObservable()
          .pipe(
            map(() => {
              var today = new Date();
              var yesterday = new Date();

              yesterday.setDate(today.getDate() - 1);
              me.dates.start = yesterday;
              me.dates.end = today;
              let start_date = dayjs(me.dates.start).format("YYYY-MM-DD HH:mm");
              let end_date = dayjs(me.dates.end).format("YYYY-MM-DD HH:mm");
              params.startDate = start_date;
              params.endDate = end_date;
            }),
            switchMap(() => {
              return from(
                me.axios.post(
                  "EventController?cmd=select-event-list",
                  qs.stringify(params)
                )
              );
            })
          )
          .subscribe((res) => {
            if (!!res.data.eventList) {
              var list = [...res.data.eventList];
              me.originEventList = list;
              me.eventList = [];
              list.sort((a, b) => {
                return b.occur_time - a.occur_time;
              });
              list.forEach((value) => {
                var equipNum;

                var event_type = null;
                var event_type_3mix = value.d_event_type;

                equipNum = value.tech == "S" ? 0 : value.tech == "R" ? 1 : 2;

                if (event_type_3mix > 9) {
                  event_type = value.d_event_type;
                } else {
                  event_type = "0" + value.d_event_type;
                }
                // var event_type = '0'+value.d_event_type;
                const id = value["camera_id"] ? value["camera_id"] : "";
                // const data={camera_id:id,eventIcon: event_type, eventType:me.selected_event_type_string[event_type], eventDirect:value.direction, eventPosition:value.position, eventRealTime:value.occur_time_string.split(' ')[1], vehicle_count:value.vehicle_count,eventEquip:equipNum};

                // 2023-05-31 hja
                // 대쉬보드 {{ $t("label.L0044") }}리스트에서 모달창 데이터 바인딩안되어 수정했음
                //
                // const data={
                // 	camera_id:id,
                // 	eventIcon: event_type,
                // 	eventType:me.selected_event_type_string[event_type],
                // 	eventDirect:value.direction,
                // 	eventPosition:value.position,
                // 	eventRealTime:value.occur_time_string.split(' ')[1],
                // 	vehicle_count:value.vehicle_count,
                // 	eventEquip:equipNum
                // };
                value["camera_id"] = id;
                value["eventIcon"] = event_type;
                value["eventType"] = me.selected_event_type_string[event_type];
                value["eventDirect"] = value.direction;
                value["eventPosition"] = value.position;
                value["eventRealTime"] = value.occur_time_string.split(" ")[1];
                value["vehicle_count"] = value.vehicle_count;
                value["eventEquip"] = equipNum;
                me.eventList.push(value);
              });

              me.paging = res.data.paging;
              me.totalCount = res.data.total;
              me.startIndex = me.paging.pageSize * (me.paging.curPage - 1);
              me.loading = false;
            }
          })
      );
    },
    async selectEventDashboardList() {
      let start_date = dayjs().startOf("day").format("YYYY-MM-DD HH:mm"); // 자정
      let end_date = dayjs().format("YYYY-MM-DD HH:mm");
      // const start_date = dayjs(this.dates.start).format("YYYY-MM-DD HH:mm");
      // const end_date = dayjs(this.dates.end).format("YYYY-MM-DD HH:mm");

      try {
        const response = await this.api.getDashboardEventList({
          tunnelCode: this.selectTunnelCode,
          linkCode: "",
          directionType: "",
          eventType: 0,
          confirmType: 0,
          endDate: end_date,
          startDate: start_date,
        });

        const { data } = response.data;

        var list = data.eventList;
        this.originEventList = data.eventList;

        var event_type = "";
        list.forEach((event) => {
          var equipNum;
          var event_type_3mix = event.dEventType;
          equipNum = event.tech == "S" ? 0 : event.tech == "R" ? 1 : 2;
          if (event_type_3mix > 9) {
            event_type = event.dEventType;
          } else {
            event_type = "0" + event.dEventType;
          }
          event["eventIcon"] = event_type;
          event["eventDirect"] =
            event.bound == 1 ? this.$t("label.L0019") : this.$t("label.L0020"); // event.direction 1:상행 0:하행
          event["eventRealTime"] = event.occurTime.split(" ")[1];
          event["eventEquip"] = equipNum;
          event["eventType"] = this.selected_event_type_string[event_type];
        });
        this.eventList = list;
      } catch (err) {
        console.log("EventTable Error.. msg : ", err);
      }
    },
    saveSoundData(params) {
      return this.axios.post(
        "EventController?cmd=update-event-vehicleCnt",
        qs.stringify(params)
      );
    },
    convertDateFormat(dateStr) {
      var year = dateStr.substring(0, 4);
      var month = dateStr.substring(4, 6);
      var day = dateStr.substring(6, 8);
      var hour = dateStr.substring(8, 10);
      var minute = dateStr.substring(10, 12);
      var second = dateStr.substring(12, 14);

      return hour + ":" + minute + ":" + second;
    },
    convertSoundIconFormat(wrongSound) {
      var sound = parseInt(wrongSound);
      sound = sound + 3;
      return "0" + sound;
    },
    convertSoundIconFormat2(wrongSound) {
      var sound = parseInt(wrongSound);
      sound = sound + 3;
      return String(sound);
    },
    routeEvent: function () {
      this.$router.push({ name: "event" });
      EventBus.$emit("moveMenu", "/event");
    },
  },
  mounted: function () {
    // this.selectEventDashboardList();
  },
  created: function () {
    // console.log(":::",this.$i18n.locale);
    this.updateDate$.next();
    var today = new Date();
    var yesterday = new Date();
    var me = this;
    yesterday.setDate(today.getDate() - 1);
    this.dates.start = yesterday;
    this.dates.end = today;
    this.selectedEvent = "i_info.result";
    this.selectedLinkCode = "WYT";
    // this.selectEventList(1);

    // {{ $t("label.L0044") }} 테이블 초기화
    // EventBus.$on("eventTableInit", function () {
    //   // me.selectEventList(1);
    // });

    EventBus.$on("selectTunnelCode", (val) => {
      // state.vue에서 터널코드 값을 받아와 세팅 후 api 호출
      this.selectTunnelCode = val;
      this.selectEventDashboardList();
    });
    // 레이더

    EventBus.$on("occurEvent", (val) => {
      me.selectEventDashboardList();
    });

    me.socket.addListener(this, "eventTable", function (data) {
      me.selectEventDashboardList();
    });

    // // 사운드
    // me.socket.addListener(this, "wrongSoundInfo", function (data) {
    //   // console.log('wrongSoundInfo');
    //   let deviceId = data.deviceId;
    //   let date = data.occurTime;
    //   let splitDate = date.split(" ");
    //   let time = splitDate[1].split("-");
    //   let wrongSound = me.convertSoundIconFormat(data.wrongSound);
    //   let wrongSound2 = me.convertSoundIconFormat2(data.wrongSound);
    //   let direct = "";
    //   let position = "-";
    //   let bound = 0;
    //   var vehicle_count = data.VehicleCount;
    //   var deviceIdData = "";
    //   for (var i = 0; i < me.deviceInfo.length; i++) {
    //     if (me.deviceInfo[i].ip == deviceId) {
    //       direct = me.deviceInfo[i].direct;
    //       position = me.deviceInfo[i].position;
    //       (bound = me.deviceInfo[i].bound),
    //         (deviceIdData = me.deviceInfo[i].deviceId);
    //     }
    //   }

    //   if (bound === 0) {
    //     vehicle_count = me.upVehcleCount;
    //   } else {
    //     vehicle_count = me.downVehcleCount;
    //   }
    //   const params = {
    //     occurTime: date.replace(/-/g, "").replace(/ /g, ""),
    //     cameraId: deviceIdData,
    //     dEventType: wrongSound2,
    //     vehicleCount: vehicle_count,
    //   };
    //   //
    //   // string occurTime -> YYYYMMDDHH24MISS 형식으로
    //   // string cameraId -> deviceId
    //   // string dEventType
    //   me.saveSoundData(params).then((value) => {
    //     me.eventList.unshift({
    //       eventIcon: wrongSound,
    //       eventType: me.selected_event_type_string[wrongSound],
    //       vehicle_count: vehicle_count,
    //       eventDirect: direct,
    //       eventPosition: position,
    //       eventRealTime: time[0] + ":" + time[1] + ":" + time[2],
    //       eventEquip: 0,
    //     });
    //     me.updateDate$.next();
    //   });
    // });
    me.subs.add(
      getUpCount()
        .pipe(filter((value) => value !== this.upVehcleCount))
        .subscribe((value) => {
          this.upVehcleCount = value;
        })
    );
    me.subs.add(
      getDownCount()
        .pipe(filter((value) => value !== this.downVehcleCount))
        .subscribe((value) => {
          this.downVehcleCount = value;
        })
    );
    me.subs.add(
      getToggle().subscribe((value) => {
        this.toggle = value;
      })
    );
    me.subs.add(
      getOpenPopup().subscribe((item) => {
        me.clickInfo(item);
      })
    );
  },
  beforeDestroy: function () {
    // this.socket.deleteListener(this);
    this.subs.unsubscribe();
  },
};
</script>
<style>
.event_table tr th {
  text-align: center;
  padding: 0.6vw;
  font: bold normal normal 0.8vw NotoSansKR;
}

.event_table tr td {
  text-align: center;
  padding: 0.4vw;
  font: normal normal normal 0.7vw NotoSansKR;
}

.dashboard_rtc_panel {
  background: rgba(44, 44, 44, 1) 0% 0% no-repeat padding-box;
  box-shadow: 5px 10px 22px #00000029;
  border-radius: 10px;
  opacity: 1;
  width: 100%;
  height: 100%;
}

.dashboard_rtc_panel_event {
  background: rgba(44, 44, 44, 1) 0% 0% no-repeat padding-box;
  box-shadow: 5px 10px 22px #00000029;
  border: 2px solid rgb(68, 68, 68);
  border-radius: 10px;
  opacity: 1;
  width: 100%;
  height: 100%;
}

.dashboard_rtc_panel .cctv_container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.dashboard_rtc_panel .cctv_container .cctv_row {
  width: 100%;
}

.container-fluid {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.div_cctv_left {
  width: 40.8%;
  height: 100%;
  margin-left: 0.2%;
}

.div_cctv_right {
  width: 58%;
  height: 100%;
  margin-right: 1%;
}

.main_cctv {
  /* width: 100%; */
  height: 100%;
  padding: 0.3rem;
  flex: 1;
  display: flex;
  align-items: flex-end;
}

.sub_cctv {
  width: 33.3%;
  height: 100%;
  padding: 1vh;
}

.dashboard_rtc_panel .cctv_overlay_video {
  position: absolute;
  background-color: rgba(16, 20, 35, 0.5);
  color: #e1e1e1;
  display: flex;
  padding: 0 1rem;
  align-items: center;
  font-size: 0.8rem;
  overflow: hidden;
}

.list-area {
  display: flex;
  flex-direction: column;
  /* padding-top: 5px;
	position: relative;
	left: 26px;
	height: 100%;
	display: block;
	width: 100%;
	color: rgb(114, 114, 114); */
}

.divTableRow {
  display: flex;
  padding-top: 20px;
  color: white;
  /* display: table-row; */
}
.divTableHead {
  display: flex;
  justify-content: center;
  align-items: center;
  font: bold normal normal 1rem NotoSansKR;
  flex: 1;
  /* width: 18%;
	text-align: center;
	font-size: 100%; */
}
@media only screen and (max-width: 800px) {
  .divTableRow {
    flex-wrap: wrap;
  }

  .divTableHead {
    width: 100%;
  }

  img {
    width: 10%;
  }
}
@media screen and (max-width: 768px) {
  .list-area {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

.divTable {
  display: table;
  width: 100%;
  text-align: center;
}
.rowHeight {
  /* height: 100%;
	width: 100%; */
  display: block;
}
.divTableHeading {
  background-color: #eee;
  display: table-header-group;
}
.divTableCell {
  height: 30px;
  font-family: "NotoSansKR" !important;
  font-size: 0.7vw;
  color: #e2e2e2;
  /* padding-top: 10px; */
  padding-top: 0.25rem;
  /* padding-left: 10px; */
}

.divTableHeading {
  background-color: #eee;
  display: table-header-group;
  font-weight: bold;
}
.divTableFoot {
  background-color: #eee;
  display: table-footer-group;
  font-weight: bold;
}
.divTableBody {
  display: table-row-group;
}

.divTableCell img {
  width: 1vw;
  margin-right: 0.3vw;
}
.divTableCell span {
  width: 50px;
}
.toggleStyle {
  font-size: 0.7vw !important;
}
.virtual-scroll-box {
  width: 100%;
  height: 100%;
}
.virtualList li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
</style>
<style>
::-webkit-scrollbar {
  width: 10px !important; /* 스크롤 바 너비 */
}

::-webkit-scrollbar-thumb {
  background-color: #888 !important; /* 스크롤 바 색상 */
  border-radius: 5px !important;
}

::-webkit-scrollbar-track {
  background-color: #2c2c2c !important; /* 스크롤 바 트랙 색상 */
  border-radius: 5px !important;
}
</style>

<style scoped>
thead th {
  width: 23%;
  text-align: center;
}
</style>
